import { useCallback, useState } from 'react';

import { NeighborhoodService } from '../service/Neighborhood.service';

export const useVacateHome = (): [
  (homeId: number) => void,
  boolean,
  string | null,
] => {
  const { vacateHome } = NeighborhoodService();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const handleVacate = useCallback(
    async (homeId: number) => {
      setLoading(true);
      setError(null);
      try {
        await vacateHome(homeId);
        window.location.reload();
      } catch (error) {
        console.error('Error desocupando el apartamento/casa:', error);
        setError('Hubo un error al desocupar el apartamento/casa');
      } finally {
        setLoading(false);
      }
    },
    [vacateHome],
  );

  return [handleVacate, loading, error];
};
