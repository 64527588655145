import React from 'react';

import { Icon } from '../../atoms/Icon';
import { Image } from '../../atoms/Image';
import Text from '../../atoms/Text';

import styles from './styles.module.scss';
import { ITableRowProps } from './types';

const TableRow: React.FC<ITableRowProps> = ({
  rowIndex,
  rowData,
  imageSrc,
  firstIcon,
  secondIcon,
  firstAction,
  secondAction,
  onRowClick,
}) => {
  return (
    <tr onClick={() => onRowClick && onRowClick(rowIndex)}>
      {imageSrc && (
        <td className={styles.cell}>
          <Image srcName={imageSrc} width={40} height={40} />
        </td>
      )}
      {rowData.map((cell, index) => (
        <td key={index} className={styles.cell}>
          <Text text={cell.text || ''} textStyle='B1-Regular' />
        </td>
      ))}
      {secondIcon && (
        <td className={styles.cell}>
          <Icon
            width={25}
            height={25}
            color='primary'
            onClick={() => firstAction && firstAction(rowIndex)}
          >
            {firstIcon}
          </Icon>
        </td>
      )}
      {secondIcon && (
        <td className={styles.cell}>
          <Icon
            width={25}
            height={25}
            color='primary'
            onClick={() => secondAction && secondAction(rowIndex)}
          >
            {secondIcon}
          </Icon>
        </td>
      )}
    </tr>
  );
};

export { TableRow };
