import { LogOut, NavArrowDown } from 'iconoir-react';

import React, { useState } from 'react';

import { DropMenu } from '../../atoms/DropMenu';
import { Icon } from '../../atoms/Icon';
import Text from '../../atoms/Text';

import { useAuthContext } from '../../../authentication/core/context/Authentication.context';
import styles from './styles.module.scss';
import { ITopBarProps } from './types';

const TopBar: React.FC<ITopBarProps> = ({ title }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { logout, authState } = useAuthContext();
  const userName = authState?.user_name || 'Admin';

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const menuItems = [
    {
      label: 'Cerrar sesión',
      onClick: () => logout(),
      icon: (
        <Icon color={'black'} width={20} height={20}>
          <LogOut />
        </Icon>
      ),
    },
  ];

  return (
    <div className={styles.container}>
      <Text
        text={title}
        textStyle={'SH1-Bold'}
        className={styles.title}
        color='primary'
      />
      <div className={styles.userContainer}>
        <Text text={userName} textStyle={'SH2-Regular'} />
        <div className={`${styles.icon} ${isMenuOpen ? styles.iconOpen : ''}`}>
          {' '}
          <Icon
            color={'black'}
            width={24}
            height={24}
            strokeWidth={1.5}
            onClick={toggleMenu}
          >
            <NavArrowDown />
          </Icon>
        </div>
        <div className={styles.menu}>
          <DropMenu items={menuItems} isOpen={isMenuOpen} />
        </div>
      </div>
    </div>
  );
};

export { TopBar };
