import axios, { AxiosResponse } from 'axios';

import { useNavigate } from 'react-router-dom';

import { LocalStorageVault } from '../../../../utils/LocalStorageVault';

import { ENV_VARIABLES } from '../../../../config/env';
import { useAuthContext } from '../context/Authentication.context';
import { AuthRequestDTO } from '../dto/AuthRequest.dto';
import { AuthResponseDTO } from '../dto/AuthResponse.dto';
import { User } from '../entity/User.entity';
import { SignInUseCase } from '../usecase/SignIn.usecase';

export class AuthenticationService implements SignInUseCase {
  private authInfo = useAuthContext();
  private storage = new LocalStorageVault();
  private navigate = useNavigate();

  async signInWithCredentials(auth: AuthRequestDTO): Promise<AxiosResponse> {
    return await axios.post(
      `${ENV_VARIABLES.BACKEND_URL}/auth/admin/login`,
      auth,
    );
  }

  async saveAuthInVault(user: User): Promise<void> {
    this.storage.saveValueInStorage('auth', user);
  }

  async signIn(auth: AuthRequestDTO): Promise<void> {
    try {
      const response = await this.signInWithCredentials(auth);
      if ((response.data as AuthResponseDTO).mandatory_change) {
        // TODO add navigation to firstPasswrd
        // this.navigate("/FirstPassword", {
        //   state: {
        //     credentials: {
        //       email: auth.email,
        //       password: auth.password,
        //     },
        //     auth: response.data,
        //   },
        // });
        this.saveAuthInVault(response.data);
        this.authInfo.setAuthState(response.data);
        this.navigate('/neighborhood');
      } else {
        this.saveAuthInVault(response.data);
        this.authInfo.setAuthState(response.data);
        this.navigate('/neighborhood');
      }
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 401) {
        throw new Error('Invalid credentials');
      } else {
        console.error(error);
        throw new Error('An unexpected error occurred');
      }
    }
  }
}
