import axios, { AxiosError } from 'axios';

import { Dispatch, SetStateAction, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { useActionsHandleErrors } from './options';
import {
  AsyncFunction,
  ErrorFunction,
  ErrorsResponse,
  FinallyFunction,
} from './types';

const useHandleErrors = () => {
  const [getErrorMessage] = useActionsHandleErrors();
  const navigate = useNavigate();

  const handleErrors = useCallback(
    async (
      successFn: AsyncFunction,
      errorFn?: ErrorFunction,
      finallyFn?: FinallyFunction,
      isLoading?: Dispatch<SetStateAction<boolean>>,
      customError?: ErrorsResponse[],
    ) => {
      try {
        return await successFn();
      } catch (error) {
        if (axios.isAxiosError(error)) {
          const axiosError: AxiosError = error;
          const response = getErrorMessage(axiosError, customError);

          if (response) {
            if (response.action) {
              response.action();
            } else {
              navigate(response.redirectTo || '/error');
            }
          }
        }
        errorFn?.();
      } finally {
        finallyFn?.();
        isLoading?.(false);
      }
    },
    [getErrorMessage, navigate],
  );

  return [handleErrors];
};

export { useHandleErrors };
