import { useCallback, useState } from 'react';

import { HomeNameEntity } from '../entity/HomeName.entity';
import { ResidentService } from '../service/Residents.service';

export const useGetHomesNames = (): [
  () => void,
  HomeNameEntity[] | null,
  boolean,
  string | null,
] => {
  const { getHomesNames } = ResidentService();
  const [homesNames, setHomesNames] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const fetchHomesNames = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await getHomesNames();
      console.log('llame');
      setHomesNames(response.data);
    } catch (err) {
      console.error('Error obteniendo los nombres de los hogares:', err);
      setError('Hubo un error al obtener los nombres de los hogares');
      setHomesNames([]);
    } finally {
      setLoading(false);
    }
  }, [getHomesNames]);

  return [fetchHomesNames, homesNames, loading, error];
};
