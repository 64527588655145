import { AxiosResponse } from 'axios';

import { useAxiosInstance } from '../../../../utils/axios';

import { NeighborhoodUseCase } from '../usecase/Neighborhood.usecase';

export const NeighborhoodService = (): NeighborhoodUseCase => {
  const { axiosInstance } = useAxiosInstance();

  const getHomes = async (): Promise<AxiosResponse> => {
    return await axiosInstance.get('/admin/homes');
  };

  const vacateHome = async (homeId: number): Promise<AxiosResponse> => {
    return await axiosInstance.delete(`/admin/home/${homeId}/residents`);
  };

  const getResidents = async (homeId: number): Promise<AxiosResponse> => {
    return await axiosInstance.get(`/admin/home/${homeId}/residents`);
  };

  const updateHomeName = async (
    homeId: number,
    name: string,
  ): Promise<AxiosResponse> => {
    return await axiosInstance.put('/admin/home', { home: homeId, name });
  };

  return {
    getHomes,
    vacateHome,
    getResidents,
    updateHomeName,
  };
};
