import { useCallback, useState } from 'react';

import { NeighborhoodService } from '../service/Neighborhood.service';

export const useUpdateHomeName = (): [
  (homeId: number, name: string) => void,
  boolean,
  string | null,
] => {
  const { updateHomeName } = NeighborhoodService();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const handleUpdateHomeName = useCallback(
    async (homeId: number, name: string) => {
      setLoading(true);
      setError(null);
      try {
        await updateHomeName(homeId, name);
        window.location.reload();
      } catch (error) {
        console.error('Error actualizando el nombre del hogar:', error);
        setError('Hubo un error al actualizar el nombre del hogar');
      } finally {
        setLoading(false);
      }
    },
    [updateHomeName],
  );

  return [handleUpdateHomeName, loading, error];
};
