import React from 'react';
import { useContext } from 'react';

import { PqrsTable } from '../../../feature/pqrs/templates/PqrsTable';

import { TopBar } from '../../../feature/ui/molecules/TopBar';

import { SidebarContext } from '../../../feature/ui/organisms/Sidebar/context/SidebarContext';

import styles from './styles.module.scss';

const PqrsTemplate = () => {
  const { isSidebarOpen } = useContext(SidebarContext);
  return (
    <div
      className={`${styles.container} ${isSidebarOpen ? '' : styles.closed}`}
    >
      <TopBar title='PQRS' />
      <div className={styles.content}>
        <PqrsTable />
      </div>
    </div>
  );
};

export default PqrsTemplate;
