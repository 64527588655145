import { useCallback, useEffect, useState } from 'react';

import { HomeEntity } from '../entity/Home.entity';
import { NeighborhoodService } from '../service/Neighborhood.service';

export const useHomes = (): [HomeEntity[] | null, boolean, () => void] => {
  const { getHomes } = NeighborhoodService();
  const [homes, setHomes] = useState<HomeEntity[] | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const fetchHomes = useCallback(async () => {
    setLoading(true);
    try {
      const response = await getHomes();
      setHomes(response.data);
    } catch (error) {
      console.error(error);
      setHomes([]);
    } finally {
      setLoading(false);
    }
  }, [getHomes]);

  useEffect(() => {
    fetchHomes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [homes, loading, fetchHomes];
};
