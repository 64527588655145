import { UserPlus } from 'iconoir-react';

import React, { useEffect, useState } from 'react';
import { useContext } from 'react';

import { ResidentsTable } from '../../../feature/residents/templates/ResidentsTable';

import { Button } from '../../../feature/ui/atoms/Button';
import { Icon } from '../../../feature/ui/atoms/Icon';

import { Modal } from '../../../feature/ui/molecules/Modal';
import { Popup } from '../../../feature/ui/molecules/Popup';
import { TopBar } from '../../../feature/ui/molecules/TopBar';

import { PopupForm } from '../../../feature/ui/organisms/PopupAddForm';
import { SidebarContext } from '../../../feature/ui/organisms/Sidebar/context/SidebarContext';

import { CreateResidentEntity } from '../../../feature/residents/core/entity/CreateResident.entity';
import { useCreateResident } from '../../../feature/residents/core/hooks/CreateResident.hook';
import { useGetHomesNames } from '../../../feature/residents/core/hooks/GetHomesNames.hook';
import styles from './styles.module.scss';

const ResidentsTemplate = () => {
  const [isModalOpenAddResident, setIsModalOpenAddResident] = useState(false);
  const [isSuccessPopupOpen, setIsSuccessPopupOpen] = useState(false);
  const [fetchHomeNames, homeNamesList] = useGetHomesNames();
  const { isSidebarOpen } = useContext(SidebarContext);
  const [createResident, loadingCreateResident, successCreation] =
    useCreateResident();
  const [formData, setFormData] = useState<CreateResidentEntity>({
    first_name: '',
    last_name: '',
    document_type: '',
    document_id: '',
    email: '',
    home_id: '',
  });
  const [errors] = useState({
    first_name: '',
    last_name: '',
    document_type: '',
    document_id: '',
    email: '',
    home_id: '',
  });

  const handleChange = (field: string, value: string) => {
    setFormData({ ...formData, [field]: value });
  };

  useEffect(() => {
    if (successCreation) {
      setIsSuccessPopupOpen(true);
    }
  }, [successCreation]);

  const handleSubmit = async (values: CreateResidentEntity) => {
    await createResident(values);
    handleCloseModal();
  };

  const handleCloseSuccessPopup = () => {
    setIsSuccessPopupOpen(false);
    window.location.reload();
  };

  const handleOpenModalAddResident = () => {
    Promise.resolve(fetchHomeNames());
    setIsModalOpenAddResident(true);
  };

  const handleCloseModal = () => setIsModalOpenAddResident(false);

  const homeOptions =
    homeNamesList?.map((home) => ({
      label: home.name,
      value: home.id.toString(),
    })) || [];

  return (
    <div
      className={`${styles.container} ${isSidebarOpen ? '' : styles.closed}`}
    >
      <TopBar title='Mis residentes' />
      <div className={styles.content}>
        <Button
          text='Añadir residente'
          icon={
            <Icon color='neutral' height={22} width={22}>
              <UserPlus />
            </Icon>
          }
          color='primary'
          onPress={handleOpenModalAddResident}
          cancelButton={true}
        />
        <ResidentsTable />
      </div>
      <Modal
        isOpen={isModalOpenAddResident}
        onRequestClose={handleCloseModal}
        showCloseIcon={false}
      >
        <PopupForm
          type='resident'
          formData={formData}
          errors={errors}
          onChange={handleChange}
          onSubmit={handleSubmit}
          onClose={handleCloseModal}
          isLoading={loadingCreateResident}
          homeOptions={homeOptions}
        />
      </Modal>
      <Popup
        isOpen={isSuccessPopupOpen}
        onClose={handleCloseSuccessPopup}
        imageSrc={'resiHappy'}
        width={200}
        titleText='¡Residente creado con éxito!'
        buttons={false}
      />
    </div>
  );
};

export default ResidentsTemplate;
