import { AxiosResponse } from 'axios';

import { useAxiosInstance } from '../../../../utils/axios';

import { RespondPqrsEntity } from '../entity/RespondPqrs.entity';
import { PqrsState } from '../enums/PqrsState.enum';
import { PqrsUseCase } from '../usecase/Pqrs.usecase';

export const PqrsService = (): PqrsUseCase => {
  const { axiosInstance } = useAxiosInstance();

  const getPqrs = async (state: PqrsState): Promise<AxiosResponse> => {
    return await axiosInstance.get(`/admin/pqrs?state=${state}`);
  };

  const respondPqrs = async (
    data: RespondPqrsEntity,
  ): Promise<AxiosResponse> => {
    return await axiosInstance.post(`/admin/pqrs/response`, data);
  };

  const takePqrs = async (ticketNumber: string): Promise<AxiosResponse> => {
    return await axiosInstance.put(
      `/admin/pqrs/take?ticket_number=${ticketNumber}`,
    );
  };

  return {
    getPqrs,
    respondPqrs,
    takePqrs,
  };
};
