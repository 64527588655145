import React from 'react';
import { useContext } from 'react';

import Text from '../../../../feature/ui/atoms/Text';

import { TopBar } from '../../../../feature/ui/molecules/TopBar';

import { SidebarContext } from '../../../../feature/ui/organisms/Sidebar/context/SidebarContext';

import styles from './styles.module.scss';

const PackagesTemplate = () => {
  const { isSidebarOpen } = useContext(SidebarContext);
  return (
    <div
      className={`${styles.container} ${isSidebarOpen ? '' : styles.closed}`}
    >
      {' '}
      <TopBar title='Reporte de paquetes' />
      <div className={styles.content}>
        <Text
          text='Aquí va la página de Reporte de paquetes'
          textStyle='H2-Bold'
        />
      </div>
    </div>
  );
};

export default PackagesTemplate;
