import { Xmark } from 'iconoir-react';

import React from 'react';
import Modal from 'react-modal';

import { Icon } from '../../atoms/Icon';
import Text from '../../atoms/Text';

import styles from './styles.module.scss';
import { IModalProps } from './types';

Modal.setAppElement('#root');

const ModalComponent: React.FC<IModalProps> = ({
  isOpen,
  children,
  onRequestClose,
  title,
  styleOverride,
  contentStyleOverride,
  showCloseIcon = true,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className={`${styles.modal} ${styleOverride}`}
      overlayClassName={styles.overlay}
    >
      <div className={styles.header}>
        <div className={styles.titleContainer}>
          {title && (
            <div className={styles.title}>
              <Text text={title} textStyle={'SH2-Bold'} color='primary' />
            </div>
          )}
        </div>
        {showCloseIcon && (
          <button onClick={onRequestClose} className={styles.closeButton}>
            <Icon color='primary'>
              <Xmark />
            </Icon>
          </button>
        )}
      </div>
      <div className={contentStyleOverride}>{children}</div>
    </Modal>
  );
};

export { ModalComponent as Modal };
