import { useCallback, useEffect, useState } from 'react';

import { GuardEntity } from '../entity/Guard.entity';
import { GuardsService } from '../service/Guards.service';
import { IUseGuards } from './types';

export const useGuards = (active: boolean): IUseGuards => {
  const { getGuards } = GuardsService();
  const [guards, setGuards] = useState<GuardEntity[] | null>(null);
  const [guardsLoading, setGuardsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const fetchGuards = useCallback(async () => {
    setGuardsLoading(true);
    setError(null);
    try {
      const response = await getGuards(active);
      setGuards(response.data);
    } catch (error) {
      console.error('Error obteniendo los guardas:', error);
      setError('Hubo un error al obtener los guardas');
      setGuards([]);
    } finally {
      setGuardsLoading(false);
    }
  }, [getGuards, active]);

  useEffect(() => {
    fetchGuards();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active]);

  return [guards, guardsLoading, fetchGuards, error];
};
