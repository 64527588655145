import { AxiosResponse } from 'axios';

import { useAxiosInstance } from '../../../../utils/axios';

import { ReportUseCase } from '../usecase/Report.usecase';

export const ReportService = (): ReportUseCase => {
  const { axiosInstance } = useAxiosInstance();

  const getReports = async (): Promise<AxiosResponse> => {
    return await axiosInstance.get('/admin/report/visits');
  };

  return {
    getReports,
  };
};
