import React from 'react';

import { imgs } from '../../../../assets/images';

import { IImagesProps } from './types';

const Image: React.FC<IImagesProps> = ({
  srcName,
  srcUrl,
  width = 0,
  height = 0,
  style,
}) => {
  const imageWidth = typeof width === 'string' ? width : `${width}px`;
  const imageHeight = typeof height === 'string' ? height : `${height}px`;

  return (
    <img
      src={srcUrl || imgs[srcName]}
      alt={srcName}
      className={''}
      style={{ width: imageWidth, height: imageHeight, ...style }}
    />
  );
};

export { Image };
