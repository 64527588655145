import axios from 'axios';

import { ENV_VARIABLES } from '../../config/env';
import { useAuthContext } from '../../feature/authentication/core/context/Authentication.context';

export const useAxiosInstance = () => {
  const { authState } = useAuthContext();
  const axiosInstance = axios.create({
    baseURL: ENV_VARIABLES.BACKEND_URL,
    headers: {
      Authorization: `Bearer ${authState?.access_token}`,
    },
  });

  return { axiosInstance };
};
