import { EditPencil, Prohibition } from 'iconoir-react';

import React, { useEffect, useState } from 'react';

import { LinkOrButton } from '../../ui/atoms/LinkOrButton';
import { Loading } from '../../ui/atoms/Loading';
import { SearchInput } from '../../ui/atoms/SearchInput';

import { ListEmpty } from '../../ui/molecules/ListEmpty';
import { Popup } from '../../ui/molecules/Popup';

import Table from '../../ui/organisms/Table';

import { useGuards } from '../core/hooks/GetGuards.hook';
import { useRemoveGuard } from '../core/hooks/RemoveGuard.hook';
import styles from './styles.module.scss';

export const GuardsTable: React.FC = () => {
  const [active, setActive] = useState(true);
  const [guards, guardsLoading] = useGuards(active);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredGuards, setFilteredGuards] = useState(guards || []);
  const [removePopUpOpen, setRemovePopUpOpen] = useState(false);
  const [removedSuccessPopUpOpen, setRemovedSuccessPopUpOpen] = useState(false);
  const [guardToRemove, setGuardToRemove] = useState<number | null>(null);
  const [handleRemoveGuard, loading] = useRemoveGuard();

  useEffect(() => {
    if (guards) {
      setFilteredGuards(
        guards.filter(
          (guard) =>
            guard.guard_first_name
              .toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            guard.guard_last_name
              .toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            guard.document_id.toString().includes(searchTerm),
        ),
      );
    }
  }, [searchTerm, guards]);

  const columns = [
    { title: 'Nombre', sortable: true, type: 'string' as const },
    { title: 'Apellido', sortable: true, type: 'string' as const },
    { title: 'Tipo documento', sortable: true, type: 'string' as const },
    { title: '# Documento', sortable: true, type: 'number' as const },
    { title: 'Correo', sortable: true, type: 'string' as const },
  ];

  const data =
    filteredGuards?.map((guard) => [
      { text: guard.guard_first_name },
      { text: guard.guard_last_name },
      { text: guard.document_type },
      { text: guard.document_id.toString() },
      { text: guard.email },
    ]) || [];

  const openRemovePopup = (id: number) => {
    setGuardToRemove(id);
    setRemovePopUpOpen(true);
  };

  const handleCloseConfirmPopup = () => {
    setRemovePopUpOpen(false);
    setGuardToRemove(null);
  };

  const handleCloseRemovedPopup = () => {
    setRemovedSuccessPopUpOpen(false);
    window.location.reload();
  };

  const handleConfirmRemoveGuard = async () => {
    if (guardToRemove !== null) {
      await handleRemoveGuard(guardToRemove);
      handleCloseConfirmPopup();
      setRemovedSuccessPopUpOpen(true);
    }
  };

  return (
    <div className={styles.main}>
      <div className={styles.topContainer}>
        <div className={styles.filterContainer}>
          <LinkOrButton
            text='Activos'
            textStyle='B1-Medium'
            onClick={() => setActive(true)}
            className={active ? styles.active : ''}
            color={active ? 'primary' : 'secondary2'}
            filter={true}
            active={active}
          />
          <LinkOrButton
            text='Inactivos'
            textStyle='B1-Medium'
            onClick={() => setActive(false)}
            className={!active ? styles.inactive : ''}
            color={!active ? 'primary' : 'secondary2'}
            filter={true}
            active={!active}
          />
        </div>
        <div className={styles.searchContainer}>
          <SearchInput
            placeholder='Buscar guardas por nombre o cédula'
            value={searchTerm}
            onChangeText={setSearchTerm}
          />
        </div>
      </div>
      <div className={styles.tableContainer}>
        {guardsLoading ? (
          <Loading size='small' backgroundColor={true} />
        ) : guards ? (
          filteredGuards.length > 0 ? (
            <Table
              imageSrc='guard'
              columns={columns}
              data={data}
              firstIcon={active ? <EditPencil /> : null}
              secondIcon={active ? <Prohibition /> : null}
              secondAction={(rowIndex: number) =>
                openRemovePopup(filteredGuards[rowIndex].id)
              }
            />
          ) : (
            <ListEmpty width={150.6} height={164.3} />
          )
        ) : (
          <Loading size='small' backgroundColor={true} />
        )}
      </div>
      <Popup
        isOpen={removePopUpOpen}
        onClose={handleCloseConfirmPopup}
        imageSrc={'resiLook'}
        titleText='¿Deseas deshabilitar a este guardia?'
        whiteText='Cerrar'
        width={260}
        redText='Confirmar'
        buttons={true}
        onConfirmation={handleConfirmRemoveGuard}
        isLoading={loading}
      />
      <Popup
        isOpen={removedSuccessPopUpOpen}
        onClose={handleCloseRemovedPopup}
        imageSrc={'resiHappy'}
        width={200}
        titleText='¡Guarda deshabilitado con éxito!'
        buttons={false}
      />
    </div>
  );
};
