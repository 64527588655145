import { AxiosError } from 'axios';

import { useAuthContext } from '../../feature/authentication/core/context/Authentication.context';
import { LocalStorageVault } from '../LocalStorageVault';
import { ErrorsResponse, IUseActionsHandleErrors } from './types';

export const useActionsHandleErrors = (): IUseActionsHandleErrors => {
  const vault = new LocalStorageVault();
  const errorOptions: ErrorsResponse[] = [
    {
      code: 401,
      message: 'Correo y/o contraseña incorrectos.',
    },
    {
      code: 403,
      message: 'Sesión inválida.',
      action: () => logOut(),
      redirectTo: '/login',
    },
    {
      code: 404,
      message: 'Página no encontrada.',
      redirectTo: '/404',
    },
  ];

  const { setAuthState } = useAuthContext();

  const logOut = () => {
    vault.resetStorage('auth');
    setAuthState(undefined);
  };

  const getErrorMessage = (
    error: AxiosError,
    customError?: ErrorsResponse[],
  ): ErrorsResponse | undefined => {
    const { status } = error;

    if (customError) {
      const result =
        customError.find(({ code }) => code === status) || undefined;
      if (result) {
        return result;
      }
    }

    return errorOptions.find(({ code }) => code === status) || undefined;
  };

  return [getErrorMessage];
};
