import { IconoirProvider } from 'iconoir-react';

import React from 'react';

import { colors } from '../../../../utils/styles/colors';

import { IconProps } from './types';

const Icon = ({
  color = 'neutral',
  strokeWidth = 2,
  height = 24,
  width = 24,
  children,
  onClick,
}: IconProps) => {
  return (
    <div style={{ cursor: 'pointer' }} onClick={onClick}>
      <IconoirProvider
        iconProps={{
          color: colors[color],
          strokeWidth: strokeWidth,
          height: height,
          width: width,
        }}
      >
        {children}
      </IconoirProvider>
    </div>
  );
};

export { Icon };
