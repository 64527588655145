import { UserPlus } from 'iconoir-react';

import React, { useEffect, useState } from 'react';
import { useContext } from 'react';

import { GuardsTable } from '../../../feature/guards/templates/GuardsTable';

import { Button } from '../../../feature/ui/atoms/Button';
import { Icon } from '../../../feature/ui/atoms/Icon';

import { Modal } from '../../../feature/ui/molecules/Modal';
import { Popup } from '../../../feature/ui/molecules/Popup';
import { TopBar } from '../../../feature/ui/molecules/TopBar';

import { PopupForm } from '../../../feature/ui/organisms/PopupAddForm';
import { SidebarContext } from '../../../feature/ui/organisms/Sidebar/context/SidebarContext';

import { useCreateGuard } from '../../../feature/guards/core/hooks/AddGuard.hook';
import styles from './styles.module.scss';

const GuardsTemplate = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSuccessPopupOpen, setIsSuccessPopupOpen] = useState(false);
  const [createGuard, loadingCreateGuard, successCreation] = useCreateGuard();
  const { isSidebarOpen } = useContext(SidebarContext);
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    document_type: '',
    document_id: '',
    email: '',
    home_id: '',
  });
  const [errors] = useState({
    first_name: '',
    last_name: '',
    document_type: '',
    document_id: '',
    email: '',
  });

  const handleOpenModal = () => setIsModalOpen(true);

  const handleChange = (field: string, value: string) => {
    setFormData({ ...formData, [field]: value });
  };

  useEffect(() => {
    if (successCreation) {
      setIsSuccessPopupOpen(true);
    }
  }, [successCreation]);

  const handleSubmit = async (values: typeof formData) => {
    await createGuard(values);
    handleCloseModal();
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleCloseSuccessPopup = () => {
    setIsSuccessPopupOpen(false);
    window.location.reload();
  };

  return (
    <div
      className={`${styles.container} ${isSidebarOpen ? '' : styles.closed}`}
    >
      <TopBar title='Mis guardas' />
      <div className={styles.content}>
        <Button
          text='Añadir guarda'
          icon={
            <Icon color='neutral' height={22} width={22}>
              <UserPlus />
            </Icon>
          }
          color='primary'
          onPress={handleOpenModal}
          cancelButton={true}
        />
        <GuardsTable />
      </div>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={handleCloseModal}
        showCloseIcon={false}
      >
        <PopupForm
          type='guard'
          formData={formData}
          errors={errors}
          onChange={handleChange}
          onSubmit={handleSubmit}
          onClose={handleCloseModal}
          isLoading={loadingCreateGuard}
        />
      </Modal>
      <Popup
        isOpen={isSuccessPopupOpen}
        onClose={handleCloseSuccessPopup}
        imageSrc={'resiHappy'}
        width={200}
        titleText='¡Guarda creado con éxito!'
        buttons={false}
      />
    </div>
  );
};

export default GuardsTemplate;
