import { useCallback, useState } from 'react';

import { GuardsService } from '../service/Guards.service';

export const useRemoveGuard = (): [
  (id: number) => Promise<void>,
  boolean,
  string | null,
] => {
  const { removeGuard } = GuardsService();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleRemoveGuard = useCallback(
    async (id: number) => {
      setLoading(true);
      setError(null);
      try {
        await removeGuard(id);
      } catch (error) {
        console.error('Error removiendo el guarda:', error);
        setError('Hubo un error al remover el guarda');
      } finally {
        setLoading(false);
      }
    },
    [removeGuard],
  );

  return [handleRemoveGuard, loading, error];
};
