import { VaultUseCase } from './type';

export class LocalStorageVault implements VaultUseCase {
  async saveValueInStorage(key: string, value: unknown): Promise<void> {
    localStorage.setItem(key, JSON.stringify(value));
  }
  async getValueFromStorage(key: string): Promise<unknown> {
    const value = localStorage.getItem(key);
    return value ? JSON.parse(value) : null;
  }
  async resetStorage(key: string): Promise<void> {
    localStorage.removeItem(key);
  }
}
