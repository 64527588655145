import { Eye, EyeClosed, XmarkCircle } from 'iconoir-react';

import React, { useState } from 'react';

import Text from '../Text';
import styles from './styles.module.scss';
import { IInputProps } from './types';

const Input = ({
  label,
  icon,
  placeholder,
  labelTextColor = 'black',
  secureTextEntry,
  error,
  onChangeText,
  onBlur,
  value,
  style,
  inputStyle,
  autoCapitalize = 'sentences',
  inputMode = 'text',
  modernStyle = false,
  maxLength,
  loginInput = false,
}: IInputProps) => {
  const [isSecureText, setIsSecureText] = useState<boolean>(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChangeText) {
      onChangeText(event.target.value);
    }
  };

  const handleClearInput = () => {
    if (onChangeText) {
      onChangeText('');
    }
  };

  const renderIcon = () => {
    if (secureTextEntry) {
      return (
        <div
          className={styles.eyeIcon}
          onClick={() => setIsSecureText(!isSecureText)}
        >
          {isSecureText ? (
            <Eye strokeWidth={1} />
          ) : (
            <EyeClosed strokeWidth={1} />
          )}
        </div>
      );
    } else if (loginInput === true && value) {
      return (
        <div className={styles.iconContainer} onClick={handleClearInput}>
          <XmarkCircle strokeWidth={1} />
        </div>
      );
    }
    return null;
  };

  return (
    <div
      className={`${styles.container} ${style} ${modernStyle ? styles.differentContainer : ''}`}
    >
      {label && (
        <Text
          text={label}
          textStyle='B2-Medium'
          color={labelTextColor}
          className={styles.label}
        />
      )}
      <div
        className={`${styles.inputContainer} ${
          error ? styles.errorForm : ''
        } ${inputStyle} ${modernStyle ? styles.differentInputContainer : ''}`}
      >
        {icon && icon}
        <input
          inputMode={inputMode}
          autoCapitalize={autoCapitalize}
          type={secureTextEntry && !isSecureText ? 'password' : 'text'}
          placeholder={placeholder}
          onChange={handleChange}
          onBlur={onBlur}
          value={value}
          maxLength={maxLength}
          className={`${styles.input} ${modernStyle ? styles.differentInput : ''}`}
        />
        {renderIcon()}
      </div>
      <div className={styles.error}>
        {error && <Text text={error} textStyle='B2-Medium' color={'primary'} />}
      </div>
    </div>
  );
};

export { Input };
