import { CheckCircleSolid, XmarkCircleSolid } from 'iconoir-react';

import React, { useEffect, useState } from 'react';

import Dropdown from '../../ui/atoms/Dropdown';
import { LinkOrButton } from '../../ui/atoms/LinkOrButton';
import { Loading } from '../../ui/atoms/Loading';
import { SearchInput } from '../../ui/atoms/SearchInput';

import { ListEmpty } from '../../ui/molecules/ListEmpty';
import { Popup } from '../../ui/molecules/Popup';

import Table from '../../ui/organisms/Table';

import { colors } from '../../../utils/styles/colors';

import { Decision } from '../core/enums/Decision.enum';
import { useAcceptResidentRequest } from '../core/hooks/AcceptResidents.hook';
import { useGetHomesNames } from '../core/hooks/GetHomesNames.hook';
import { useResidentsRequests } from '../core/hooks/GetResidentsRequests.hook';
import { useRejectResidentRequest } from '../core/hooks/RejectResidents.hook';
import styles from './styles.module.scss';

const ResidentsRequestsTable: React.FC = () => {
  const [filter, setFilter] = useState(Decision.PENDING);
  const [residentsRequests, loading] = useResidentsRequests(filter);
  const [acceptResidentsRequest, loadingAcceptance, successAcceptance] =
    useAcceptResidentRequest();
  const [acceptPopUpOpen, setAcceptPopUpOpen] = useState(false);
  const [acceptedSuccessPopUpOpen, setAcceptedSuccessPopUpOpen] =
    useState(false);
  const [rejectResidentsRequests, loadingRejection, successRejecting] =
    useRejectResidentRequest();
  const [rejectPopUpOpen, setRejectPopUpOpen] = useState(false);
  const [rejectedSuccessPopUpOpen, setRejectedSuccessPopUpOpen] =
    useState(false);
  const [requestToReject, setRequestToReject] = useState<number | null>(null);
  const [requestToAccept, setRequestToAccept] = useState<number | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredResidentsRequests, setFilteredResidentsRequests] = useState(
    residentsRequests || [],
  );
  const [fetchHomeNames, homeNamesList] = useGetHomesNames();
  const [selectedHome, setSelectedHome] = useState<string | undefined>(
    undefined,
  );

  useEffect(() => {
    if (residentsRequests) {
      setFilteredResidentsRequests(
        residentsRequests.filter(
          (resident) =>
            resident.first_name
              .toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            resident.last_name
              .toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            resident.document.includes(searchTerm) ||
            resident.email.toLowerCase().includes(searchTerm.toLowerCase()),
        ),
      );
    }
  }, [searchTerm, residentsRequests]);

  useEffect(() => {
    if (successAcceptance) {
      setAcceptedSuccessPopUpOpen(true);
    }
  }, [successAcceptance]);

  useEffect(() => {
    if (successRejecting) {
      setRejectedSuccessPopUpOpen(true);
    }
  }, [successRejecting]);

  const columns = [
    { title: 'Nombre', sortable: true, type: 'string' as const },
    { title: 'Apellido', sortable: true, type: 'string' as const },
    { title: 'Correo', sortable: true, type: 'string' as const },
    { title: '# Documento', sortable: true, type: 'string' as const },
    { title: 'Apartamento', sortable: true, type: 'string' as const },
  ];

  const data =
    filteredResidentsRequests?.map((resident) => [
      { text: resident.first_name },
      { text: resident.last_name },
      { text: resident.email },
      { text: resident.document },
      { text: resident.house },
    ]) || [];

  const openRejectPopup = (id: number) => {
    setRequestToReject(id);
    setRejectPopUpOpen(true);
  };

  const handleCloseRejectPopup = () => {
    setRejectPopUpOpen(false);
    setRequestToReject(null);
  };

  const handleCloseRejectedPopup = () => {
    setRejectedSuccessPopUpOpen(false);
    window.location.reload();
  };

  const handleConfirmRejectRequest = async () => {
    if (requestToReject !== null) {
      await rejectResidentsRequests(requestToReject);
      handleCloseRejectPopup();
    }
  };

  const openAcceptPopup = (id: number) => {
    setRequestToAccept(id);
    Promise.resolve(fetchHomeNames());
    setAcceptPopUpOpen(true);
  };

  const handleCloseAcceptPopup = () => {
    setAcceptPopUpOpen(false);
    setRequestToAccept(null);
    setSelectedHome(undefined);
  };

  const handleCloseAcceptedPopup = () => {
    setAcceptedSuccessPopUpOpen(false);
    window.location.reload();
  };

  const handleConfirmAcceptRequest = async () => {
    if (requestToAccept !== null && selectedHome !== undefined) {
      await acceptResidentsRequest(requestToAccept, parseInt(selectedHome));
      handleCloseAcceptPopup();
    }
  };

  const homeOptions =
    homeNamesList?.map((home) => ({
      label: home.name,
      value: home.id.toString(),
    })) || [];

  return (
    <div className={styles.main}>
      <div className={styles.topContainer}>
        <div className={styles.filterContainer}>
          <LinkOrButton
            text='Pendientes'
            textStyle='B1-Medium'
            onClick={() => setFilter(Decision.PENDING)}
            className={filter === Decision.PENDING ? styles.active : ''}
            color={filter === Decision.PENDING ? 'primary' : 'secondary2'}
            filter={true}
            active={filter === Decision.PENDING}
          />
          <LinkOrButton
            text='Aceptadas'
            textStyle='B1-Medium'
            onClick={() => setFilter(Decision.APPROVED)}
            className={filter === Decision.APPROVED ? styles.active : ''}
            color={filter === Decision.APPROVED ? 'primary' : 'secondary2'}
            filter={true}
            active={filter === Decision.APPROVED}
          />
          <LinkOrButton
            text='Rechazadas'
            textStyle='B1-Medium'
            onClick={() => setFilter(Decision.REJECTED)}
            className={filter === Decision.REJECTED ? styles.active : ''}
            color={filter === Decision.REJECTED ? 'primary' : 'secondary2'}
            filter={true}
            active={filter === Decision.REJECTED}
          />
        </div>
        <div className={styles.searchContainer}>
          <SearchInput
            placeholder='Buscar solicitudes por nombre, apellido, documento o correo'
            value={searchTerm}
            onChangeText={setSearchTerm}
          />
        </div>
      </div>
      <div className={styles.tableContainer}>
        {loading ? (
          <Loading size='small' backgroundColor={true} />
        ) : filteredResidentsRequests.length > 0 ? (
          <Table
            imageSrc='resident'
            columns={columns}
            data={data}
            firstIcon={
              filter === Decision.PENDING ? (
                <CheckCircleSolid color={colors.primary} />
              ) : null
            }
            secondIcon={
              filter === Decision.PENDING ? (
                <XmarkCircleSolid color={colors.tertiary3} />
              ) : null
            }
            firstAction={(rowIndex: number) =>
              openAcceptPopup(filteredResidentsRequests[rowIndex].id)
            }
            secondAction={(rowIndex: number) =>
              openRejectPopup(filteredResidentsRequests[rowIndex].id)
            }
          />
        ) : (
          <ListEmpty />
        )}
      </div>
      <Popup
        isOpen={rejectPopUpOpen}
        onClose={handleCloseRejectPopup}
        imageSrc={'resiSurprised'}
        titleText='¿Deseas rechazar esta solicitud?'
        whiteText='Cerrar'
        width={200}
        redText='Confirmar'
        buttons={true}
        onConfirmation={handleConfirmRejectRequest}
        isLoading={loadingRejection}
      />
      <Popup
        isOpen={acceptPopUpOpen}
        onClose={handleCloseAcceptPopup}
        imageSrc={'resiThumbUp'}
        titleText='¿Deseas aceptar esta solicitud?'
        whiteText='Cerrar'
        width={200}
        redText='Confirmar'
        buttons={true}
        onConfirmation={handleConfirmAcceptRequest}
        isLoading={loadingAcceptance}
        messageText='Confirma el hogar del nuevo residente'
      >
        <div className={styles.dropContainer}>
          <Dropdown
            label='Seleccionar residencia'
            labelColor='gray'
            options={homeOptions}
            selectedValue={selectedHome}
            onValueChange={(value) => setSelectedHome(value)}
            searchEnabled={true}
          />
        </div>
      </Popup>
      <Popup
        isOpen={rejectedSuccessPopUpOpen}
        onClose={handleCloseRejectedPopup}
        imageSrc={'resiLook'}
        width={260}
        titleText='¡Solicitud rechazada!'
        buttons={false}
      />
      <Popup
        isOpen={acceptedSuccessPopUpOpen}
        onClose={handleCloseAcceptedPopup}
        imageSrc={'resiHappy'}
        width={260}
        titleText='¡Solicitud aceptada!'
        buttons={false}
      />
    </div>
  );
};

export { ResidentsRequestsTable };
