import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { ResidentService } from '../service/Residents.service';

export const useRemoveResident = (): [
  (id: number) => Promise<void>,
  boolean,
  boolean,
  string | null,
] => {
  const { removeResident } = ResidentService();
  const [loadingRemove, setLoadingRemove] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState(false);

  const handleRemoveResident = async (id: number) => {
    setLoadingRemove(true);
    setError(null);
    setSuccess(false);
    try {
      await removeResident(id);
      setSuccess(true);
    } catch (err) {
      console.error('Error deshabilitando al residente:', err);
      setError('Hubo un error al deshabilitar al residente');
    } finally {
      setLoadingRemove(false);
    }
  };

  useEffect(() => {
    if (error) {
      toast.error(error);
      setError(null);
    }
  }, [error]);

  return [handleRemoveResident, loadingRemove, success, error];
};
