import { useCallback, useEffect, useState } from 'react';

import { VisitEntity } from '../entity/Visit.entity';
import { ReportService } from '../service/Report.service';

export const useReports = (): [VisitEntity[] | null, () => void] => {
  const { getReports } = ReportService();
  const [reports, setReports] = useState<VisitEntity[] | null>(null);

  const fetchReports = useCallback(async () => {
    try {
      const response = await getReports();
      console.log('intente traeer info');
      setReports(response.data);
    } catch (error) {
      console.error(error);
      setReports([]);
    }
  }, [getReports]);

  useEffect(() => {
    fetchReports();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [reports, fetchReports];
};
