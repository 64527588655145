import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { ResidentEntity } from '../entity/Resident.entity';
import { ResidentService } from '../service/Residents.service';

export const useResidents = (
  active: boolean,
): [ResidentEntity[] | null, boolean, () => void, string | null] => {
  const { getResidents } = ResidentService();
  const [residents, setResidents] = useState<ResidentEntity[] | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const fetchResidents = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await getResidents(active);
      setResidents(response.data);
    } catch (err) {
      console.error('Error fetching residents:', err);
      setError('Hubo un error al obtener los residentes');
    } finally {
      setLoading(false);
    }
  }, [getResidents, active]);

  useEffect(() => {
    fetchResidents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active]);

  useEffect(() => {
    if (error) {
      toast.error(error);
      setError(null);
    }
  }, [error]);

  return [residents, loading, fetchResidents, error];
};
