import { useCallback, useState } from 'react';

import { RespondPqrsEntity } from '../entity/RespondPqrs.entity';
import { PqrsService } from '../service/Pqrs.service';

export const useRespondPqrs = (): [
  (data: { ticket_number: string; response: string }) => Promise<void>,
  boolean,
  boolean,
  string | null,
] => {
  const { respondPqrs } = PqrsService();
  const [loadingRespondPqrs, setLoadingRespondPqrs] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [successResponse, setSuccessResponse] = useState(false);

  const handleRespondPqrs = useCallback(
    async (data: RespondPqrsEntity) => {
      setLoadingRespondPqrs(true);
      setError(null);
      setSuccessResponse(false);
      try {
        await respondPqrs(data);
        setSuccessResponse(true);
      } catch (error) {
        console.error('Error respondiendo a la PQRS:', error);
        setError('Hubo un error al responder a la PQRS');
        setSuccessResponse(false);
      } finally {
        setLoadingRespondPqrs(false);
      }
    },
    [respondPqrs],
  );

  return [handleRespondPqrs, loadingRespondPqrs, successResponse, error];
};
