import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { ResidentService } from '../service/Residents.service';

export const useAcceptResidentRequest = (): [
  (requestId: number, homeId: number) => Promise<void>,
  boolean,
  boolean,
  string | null,
] => {
  const { acceptResidentsRequests } = ResidentService();
  const [loadingAcceptance, setLoadingAcceptance] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState(false);

  const acceptResidentsRequest = async (requestId: number, homeId: number) => {
    setLoadingAcceptance(true);
    setError(null);
    setSuccess(false);
    try {
      await acceptResidentsRequests(requestId, homeId);
      setSuccess(true);
    } catch (err) {
      console.error('Error aceptando la solicitud:', err);
      setError('Hubo un error al aceptar la solicitud');
    } finally {
      setLoadingAcceptance(false);
    }
  };

  useEffect(() => {
    if (error) {
      toast.error(error);
      setError(null);
    }
  }, [error]);

  return [acceptResidentsRequest, loadingAcceptance, success, error];
};
