import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { CreateResidentEntity } from '../entity/CreateResident.entity';
import { ResidentService } from '../service/Residents.service';
import { IUseCreateResident } from './types';

export const useCreateResident = (): IUseCreateResident => {
  const { createResident } = ResidentService();
  const [loadingCreateResident, setLoadingCreateResident] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [successCreation, setSuccessCreation] = useState(false);

  const handleCreateResident = useCallback(
    async (data: CreateResidentEntity) => {
      setLoadingCreateResident(true);
      setError(null);
      setSuccessCreation(false);
      try {
        await createResident(data);
        setSuccessCreation(true);
        console.log(successCreation);
      } catch (err) {
        console.error('Error creando el residente:', err);
        setError('Hubo un error al crear el residente');
        setSuccessCreation(false);
      } finally {
        setLoadingCreateResident(false);
      }
    },
    [createResident, successCreation],
  );

  useEffect(() => {
    if (error) {
      toast.error(error);
      setError(null);
    }
  }, [error]);

  return [handleCreateResident, loadingCreateResident, successCreation, error];
};
