import React from 'react';
import { useContext } from 'react';

import Text from '../../../../feature/ui/atoms/Text';

import { TopBar } from '../../../../feature/ui/molecules/TopBar';

import { SidebarContext } from '../../../../feature/ui/organisms/Sidebar/context/SidebarContext';

import styles from './styles.module.scss';

const NotificationsTemplate = () => {
  const { isSidebarOpen } = useContext(SidebarContext);
  return (
    <div
      className={`${styles.container} ${isSidebarOpen ? '' : styles.closed}`}
    >
      {' '}
      <TopBar title='Notificaciones' />
      <div className={styles.content}>
        <Text text='Aquí va la página de Notificaciones' textStyle='H2-Bold' />
      </div>
    </div>
  );
};

export default NotificationsTemplate;
