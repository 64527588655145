import { useCallback, useState } from 'react';

import { ResidentEntity } from '../entity/Resident.entity';
import { NeighborhoodService } from '../service/Neighborhood.service';

export const useResidents = (): [
  (homeId: number) => void,
  ResidentEntity[] | null,
  boolean,
  string | null,
] => {
  const { getResidents } = NeighborhoodService();
  const [residents, setResidents] = useState<ResidentEntity[] | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const fetchResidents = useCallback(
    async (homeId: number) => {
      setLoading(true);
      setError(null);
      try {
        const response = await getResidents(homeId);
        setResidents(response.data);
      } catch (error) {
        console.error('Error obteniendo los residentes:', error);
        setError('Hubo un error al obtener los residentes');
        setResidents([]);
      } finally {
        setLoading(false);
      }
    },
    [getResidents],
  );

  return [fetchResidents, residents, loading, error];
};
