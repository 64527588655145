import React, { useEffect, useState } from 'react';

import { LinkOrButton } from '../../ui/atoms/LinkOrButton';
import { Loading } from '../../ui/atoms/Loading';
import { SearchInput } from '../../ui/atoms/SearchInput';

import { ListEmpty } from '../../ui/molecules/ListEmpty';
import { Popup } from '../../ui/molecules/Popup';

import Table from '../../ui/organisms/Table';

import { PqrsEntity } from '../core/entity/Pqrs.entity';
import { PqrsState } from '../core/enums/PqrsState.enum';
import { usePqrs } from '../core/hooks/GetPqrs.hook';
import { useRespondPqrs } from '../core/hooks/RespondPqrs.hook';
import { useTakePqrs } from '../core/hooks/TakePqrs.hook';
import { PetitionCard } from './PetitionCard';
import { ResponseCard } from './ResponseCard';
import styles from './styles.module.scss';

const PqrsTable = () => {
  const [filter, setFilter] = useState<PqrsState>(PqrsState.RADICADA);
  const [searchTerm, setSearchTerm] = useState('');
  const [fetchPqrs, pqrs, loading] = usePqrs();
  const [filteredPqrs, setFilteredPqrs] = useState<PqrsEntity[]>([]);
  const [selectedPqrs, setSelectedPqrs] = useState<PqrsEntity | null>(null);
  const [respondPqrs, loadingRespondPqrs, successResponse] = useRespondPqrs();
  const [isSuccessPopupOpen, setIsSuccessPopupOpen] = useState(false);
  const [takePqrs, loadingTakePqrs, successTake] = useTakePqrs();

  const handleFetchPqrs = (state: PqrsState) => {
    Promise.resolve(fetchPqrs(state));
  };

  useEffect(() => {
    handleFetchPqrs(filter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  useEffect(() => {
    if (pqrs) {
      setFilteredPqrs(
        pqrs.filter(
          (pqr) =>
            pqr.resident.toLowerCase().includes(searchTerm.toLowerCase()) ||
            pqr.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
            pqr.category.toLowerCase().includes(searchTerm.toLowerCase()) ||
            pqr.ticket.toLowerCase().includes(searchTerm.toLowerCase()) ||
            pqr.home.toLowerCase().includes(searchTerm.toLowerCase()),
        ),
      );
    }
  }, [searchTerm, pqrs]);

  useEffect(() => {
    if (successResponse) {
      setIsSuccessPopupOpen(true);
    }
  }, [successResponse]);

  useEffect(() => {
    if (successTake) {
      setFilter(PqrsState.EN_REVISION);
      handleFetchPqrs(PqrsState.EN_REVISION);
      if (selectedPqrs) {
        updateSelectedPqrsState(PqrsState.EN_REVISION);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successTake]);

  const updateSelectedPqrsState = (state: PqrsState, response?: string) => {
    if (response !== undefined) {
      setSelectedPqrs((prevSelectedPqrs) =>
        prevSelectedPqrs ? { ...prevSelectedPqrs, state, response } : null,
      );
    } else {
      setSelectedPqrs((prevSelectedPqrs) =>
        prevSelectedPqrs ? { ...prevSelectedPqrs, state } : null,
      );
    }
  };

  const handleRowClick = (rowIndex: number) => {
    const selectedPqrs = filteredPqrs[rowIndex];
    setSelectedPqrs(selectedPqrs);
  };

  const handleResponseSubmit = async (
    ticket_number: string,
    response: string,
  ) => {
    await respondPqrs({ ticket_number, response });
    updateSelectedPqrsState(PqrsState.COMPLETADA, response);
  };

  const handleCloseSuccessPopup = () => {
    setIsSuccessPopupOpen(false);
    setFilter(PqrsState.COMPLETADA);
    handleFetchPqrs(PqrsState.COMPLETADA);
  };

  const handleInReviewClick = async (ticket: string) => {
    await takePqrs(ticket);
  };

  const handleFilterChange = (newFilter: PqrsState) => {
    setFilter(newFilter);
    setSelectedPqrs(null);
    handleFetchPqrs(newFilter);
  };

  const columns = [
    { title: 'Residente', sortable: true, type: 'string' as const },
    { title: 'Título', sortable: true, type: 'string' as const },
    { title: 'Categoría', sortable: true, type: 'string' as const },
    { title: 'Ticket', sortable: true, type: 'string' as const },
    { title: 'Residencia', sortable: true, type: 'string' as const },
  ];

  const data =
    filteredPqrs?.map((pqr) => [
      { text: pqr.resident },
      { text: pqr.title },
      { text: pqr.category },
      { text: pqr.ticket },
      { text: pqr.home },
    ]) || [];

  return (
    <div>
      <div className={styles.topContainer}>
        <div className={styles.filterContainer}>
          <LinkOrButton
            text='Radicadas'
            textStyle='B1-Medium'
            onClick={() => handleFilterChange(PqrsState.RADICADA)}
            className={filter === PqrsState.RADICADA ? styles.active : ''}
            color={filter === PqrsState.RADICADA ? 'primary' : 'secondary2'}
            filter={true}
            active={filter === PqrsState.RADICADA}
          />
          <LinkOrButton
            text='En Revisión'
            textStyle='B1-Medium'
            onClick={() => handleFilterChange(PqrsState.EN_REVISION)}
            className={filter === PqrsState.EN_REVISION ? styles.active : ''}
            color={filter === PqrsState.EN_REVISION ? 'primary' : 'secondary2'}
            filter={true}
            active={filter === PqrsState.EN_REVISION}
          />
          <LinkOrButton
            text='Completadas'
            textStyle='B1-Medium'
            onClick={() => handleFilterChange(PqrsState.COMPLETADA)}
            className={filter === PqrsState.COMPLETADA ? styles.active : ''}
            color={filter === PqrsState.COMPLETADA ? 'primary' : 'secondary2'}
            filter={true}
            active={filter === PqrsState.COMPLETADA}
          />
        </div>
        <div className={styles.searchContainer}>
          <SearchInput
            placeholder='Buscar por título, categoría o ticket'
            value={searchTerm}
            onChangeText={setSearchTerm}
          />
        </div>
      </div>
      <div className={styles.pqrs}>
        <div className={styles.tableContainer}>
          {loading ? (
            <Loading size='small' backgroundColor={true} />
          ) : filteredPqrs.length > 0 ? (
            <Table
              imageSrc='pqrs'
              columns={columns}
              data={data}
              onRowClick={handleRowClick}
            />
          ) : (
            <ListEmpty />
          )}
        </div>
        <div className={styles.detailContainer}>
          {selectedPqrs ? (
            <>
              <PetitionCard
                {...selectedPqrs}
                onButtonClick={() => handleInReviewClick(selectedPqrs.ticket)}
                isLoading={loadingTakePqrs}
              />
              {selectedPqrs.state === PqrsState.EN_REVISION ||
              selectedPqrs.state === PqrsState.COMPLETADA ? (
                <ResponseCard
                  onSubmit={handleResponseSubmit}
                  state={selectedPqrs.state}
                  response={selectedPqrs.response}
                  ticket_number={selectedPqrs.ticket}
                  isLoading={loadingRespondPqrs}
                />
              ) : null}
            </>
          ) : (
            <div className={styles.emptyCard}>
              <ListEmpty
                header={'Selecciona un elemento para leerlo'}
                imageSrc='resiTip'
                width={208.14}
                height={178.035}
                title={'No hay nada seleccionado'}
              />
            </div>
          )}
        </div>
      </div>
      <Popup
        isOpen={isSuccessPopupOpen}
        onClose={handleCloseSuccessPopup}
        imageSrc={'resiLaptop'}
        width={200}
        titleText='¡Respuesta enviada y pqrs completada correctamente!'
        buttons={false}
      />
    </div>
  );
};

export { PqrsTable };
