import React, { useState } from 'react';

import { Loading } from '../../ui/atoms/Loading';

import { Card } from '../../ui/molecules/Card';
import { ListEmpty } from '../../ui/molecules/ListEmpty';

import styles from './styles.module.scss';
import { IHomeCardProps } from './types';

export const HomeCards: React.FC<IHomeCardProps> = ({ homes }) => {
  const [openMenuId, setOpenMenuId] = useState<number | null>(null);

  const handleMenuToggle = (id: number) => {
    setOpenMenuId((prevOpenMenuId) => (prevOpenMenuId === id ? null : id));
  };

  if (!homes) {
    return <Loading size='small' backgroundColor={true} />;
  }

  if (homes.length === 0) {
    return <ListEmpty />;
  }

  return (
    <div className={styles.main}>
      <div className={styles.homesContainer}>
        {homes.map((home) => (
          <Card
            key={home.id}
            id={home.id}
            name={home.name}
            block={home.block}
            residents={home.residents}
            isMenuOpen={openMenuId === home.id}
            onMenuToggle={() => handleMenuToggle(home.id)}
          />
        ))}
      </div>
    </div>
  );
};
