import { useCallback, useState } from 'react';

import { GuardsService } from '../service/Guards.service';

export const useCreateGuard = (): [
  (data: {
    document_id: string;
    document_type: string;
    first_name: string;
    last_name: string;
    email: string;
  }) => Promise<void>,
  boolean,
  boolean,
  string | null,
] => {
  const { createGuard } = GuardsService();
  const [loadingCreateGuard, setLoadingCreateGuard] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [successCreation, setSuccessCreation] = useState(false);

  const handleCreateGuard = useCallback(
    async (data: {
      document_id: string;
      document_type: string;
      first_name: string;
      last_name: string;
      email: string;
    }) => {
      setLoadingCreateGuard(true);
      setError(null);
      setSuccessCreation(false);
      try {
        await createGuard(data);
        setSuccessCreation(true);
        console.log(successCreation);
      } catch (error) {
        console.error('Error creando el guarda:', error);
        setError('Hubo un error al crear el guarda');
        setSuccessCreation(false);
      } finally {
        setLoadingCreateGuard(false);
      }
    },
    [createGuard, successCreation],
  );

  return [handleCreateGuard, loadingCreateGuard, successCreation, error];
};
