import { AxiosResponse } from 'axios';

import { useAxiosInstance } from '../../../../utils/axios';

import { CreateResidentEntity } from '../entity/CreateResident.entity';
import { ResidentUseCase } from '../usecase/Residents.usecase';

export const ResidentService = (): ResidentUseCase => {
  const { axiosInstance } = useAxiosInstance();

  const getResidents = async (active: boolean): Promise<AxiosResponse> => {
    return await axiosInstance.get(
      `/admin/neighborhood/residents?active=${active}`,
    );
  };

  const removeResident = async (id: number): Promise<AxiosResponse> => {
    return await axiosInstance.delete(`/admin/residents/${id}`);
  };

  const createResident = async (
    data: CreateResidentEntity,
  ): Promise<AxiosResponse> => {
    return await axiosInstance.post('/admin/resident', data);
  };

  const getResidentsRequests = async (
    filter: string,
  ): Promise<AxiosResponse> => {
    return await axiosInstance.get(
      `/admin/request/residents?decision=${filter}`,
    );
  };

  const rejectResidentsRequests = async (
    id: number,
  ): Promise<AxiosResponse> => {
    return await axiosInstance.post(`/admin/request/${id}/rejection`);
  };

  const acceptResidentsRequests = async (
    requestId: number,
    homeId: number,
  ): Promise<AxiosResponse> => {
    return await axiosInstance.post(
      `/admin/home/${homeId}/request/${requestId}/accept`,
    );
  };

  const getHomesNames = async (): Promise<AxiosResponse> => {
    return await axiosInstance.get('/neighborhood/homes');
  };

  return {
    getResidents,
    removeResident,
    createResident,
    getResidentsRequests,
    rejectResidentsRequests,
    acceptResidentsRequests,
    getHomesNames,
  };
};
