import { useCallback, useState } from 'react';

import { PqrsEntity } from '../entity/Pqrs.entity';
import { PqrsState } from '../enums/PqrsState.enum';
import { PqrsService } from '../service/Pqrs.service';
import { IUseGetPqrs } from './types';

export const usePqrs = (): IUseGetPqrs => {
  const { getPqrs } = PqrsService();
  const [pqrs, setPqrs] = useState<PqrsEntity[] | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const fetchPqrs = useCallback(
    async (state: PqrsState) => {
      setLoading(true);
      setError(null);
      try {
        const response = await getPqrs(state);
        setPqrs(response.data);
      } catch (error) {
        console.error('Error obteniendo las PQRS:', error);
        setError('Hubo un error al obtener las PQRS');
        setPqrs([]);
      } finally {
        setLoading(false);
      }
    },
    [getPqrs],
  );

  return [fetchPqrs, pqrs, loading, error];
};
