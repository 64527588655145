import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { ResidentService } from '../service/Residents.service';

export const useRejectResidentRequest = (): [
  (id: number) => Promise<void>,
  boolean,
  boolean,
  string | null,
] => {
  const { rejectResidentsRequests } = ResidentService();
  const [loadingRejection, setLoadingRejection] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState(false);

  const handleRejectRequest = async (id: number) => {
    setLoadingRejection(true);
    setError(null);
    setSuccess(false);
    try {
      await rejectResidentsRequests(id);
      setSuccess(true);
    } catch (err) {
      console.error('Error rechazando la solicitud:', err);
      setError('Hubo un error al rechazar la solicitud');
    } finally {
      setLoadingRejection(false);
    }
  };

  useEffect(() => {
    if (error) {
      toast.error(error);
      setError(null);
    }
  }, [error]);

  return [handleRejectRequest, loadingRejection, success, error];
};
