import React from 'react';

import { Icon } from '../../atoms/Icon';
import { Image } from '../../atoms/Image';
import { LinkOrButton } from '../../atoms/LinkOrButton';
import Text from '../../atoms/Text';

import styles from './styles.module.scss';
import { IBannerProps } from './types';

const Banner: React.FC<IBannerProps> = ({
  icon,
  image,
  title,
  subtitle,
  textColor,
  firstOption,
  secondOption,
}) => {
  const handleLinkClick = () => {
    // TODO: Lógica para desaparecer el banner
    console.log('Banner disappeared');
  };

  return (
    <div className={styles.banner}>
      <div className={styles.content}>
        <div className={styles.imageWrapper}>
          <Image
            srcName={image.srcName}
            width={image.width}
            height={image.height}
            style={image.style}
          />
        </div>
        <div className={styles.textWrapper}>
          <Text
            text={title.text}
            textStyle={title.textStyle}
            color={textColor}
          />
          <Text
            text={subtitle.text}
            textStyle={subtitle.textStyle}
            color={textColor}
          />
          <div className={styles.linksWrapper}>
            <LinkOrButton
              href={firstOption.href}
              text={firstOption.text}
              textStyle={firstOption.textStyle}
              color={firstOption.color}
              onClick={firstOption.onClick || handleLinkClick}
              className={styles.link}
            />
            <LinkOrButton
              href={secondOption.href}
              text={secondOption.text}
              textStyle={secondOption.textStyle}
              color={secondOption.color}
              onClick={secondOption.onClick || handleLinkClick}
              className={styles.link}
            />
          </div>
        </div>
      </div>
      <div className={styles.iconWrapper}>
        <Icon
          color={icon.color}
          strokeWidth={icon.strokeWidth}
          height={icon.height}
          width={icon.width}
          onClick={icon.onClick}
        >
          {icon.children}
        </Icon>
      </div>
    </div>
  );
};

export { Banner };
