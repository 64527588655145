import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { RequestResidentEntity } from '../entity/RequestResident.entity';
import { ResidentService } from '../service/Residents.service';

export const useResidentsRequests = (
  filter: string,
): [RequestResidentEntity[] | null, boolean, string | null] => {
  const { getResidentsRequests } = ResidentService();
  const [residentsRequests, setResidentsRequests] = useState<
    RequestResidentEntity[] | null
  >(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const fetchResidentsRequests = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await getResidentsRequests(filter);
      setResidentsRequests(response.data);
    } catch (err) {
      console.error('Error fetching residents requests:', err);
      setError('Hubo un error al obtener las solicitudes de residentes');
    } finally {
      setLoading(false);
    }
  }, [getResidentsRequests, filter]);

  useEffect(() => {
    fetchResidentsRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  useEffect(() => {
    if (error) {
      toast.error(error);
      setError(null);
    }
  }, [error]);

  return [residentsRequests, loading, error];
};
