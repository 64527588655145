import { ArrowLeft } from 'iconoir-react';

import React from 'react';

import { Loading } from '../Loading';
import Text from '../Text';
import styles from './styles.module.scss';
import { IButtonProps } from './types';

const Button = ({
  text,
  textStyle = 'B1-Medium',
  icon,
  iconBack,
  onPress,
  style,
  popup,
  cancelButton,
  closeButton,
  changeButton,
  isLoading,
}: IButtonProps) => {
  return (
    <div
      className={`${styles.container} ${
        cancelButton ? styles.cancelButton : ''
      } ${closeButton ? styles.closeButton : ''} ${changeButton ? styles.changeButton : ''} ${style}`}
      onClick={isLoading ? undefined : onPress}
    >
      <div
        className={`${popup ? styles.popupContainer : styles.defaultContainer}`}
      >
        {isLoading ? (
          <div className={styles.loaderContainer}>
            <Loading size='small' backgroundColor={true} />
          </div>
        ) : (
          <>
            <div className={styles.textContent}>
              {iconBack && <ArrowLeft color={'white'} />}
              <Text
                text={text}
                textStyle={textStyle}
                color={closeButton ? 'black' : 'neutral'}
              />
            </div>
            {icon && <div className={styles.icon}>{icon}</div>}
          </>
        )}
      </div>
    </div>
  );
};

export { Button };
