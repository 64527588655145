import { AxiosResponse } from 'axios';

import { useAxiosInstance } from '../../../../utils/axios';

import { GuardsUseCase } from '../usecase/Guards.usecase';

export const GuardsService = (): GuardsUseCase => {
  const { axiosInstance } = useAxiosInstance();

  const getGuards = async (active: boolean): Promise<AxiosResponse> => {
    return await axiosInstance.get(`/admin/guards?active=${active}`);
  };

  const removeGuard = async (id: number): Promise<AxiosResponse> => {
    return await axiosInstance.delete(`/admin/guard/${id}`);
  };

  const createGuard = async (data: {
    document_id: string;
    document_type: string;
    first_name: string;
    last_name: string;
    email: string;
  }): Promise<AxiosResponse> => {
    return await axiosInstance.post('/admin/guard', data);
  };

  return {
    getGuards,
    removeGuard,
    createGuard,
  };
};
