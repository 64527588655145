import { useCallback, useState } from 'react';

import { PqrsService } from '../service/Pqrs.service';
import { IUseTakePqrs } from './types';

export const useTakePqrs = (): IUseTakePqrs => {
  const { takePqrs } = PqrsService();
  const [loadingTakePqrs, setLoadingTakePqrs] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [successResponse, setSuccessResponse] = useState(false);

  const handleRespondPqrs = useCallback(
    async (ticketNumber: string) => {
      setLoadingTakePqrs(true);
      setError(null);
      setSuccessResponse(false);
      try {
        await takePqrs(ticketNumber);
        setSuccessResponse(true);
      } catch (error) {
        console.error('Error tomando la PQRS:', error);
        setError('Hubo un error al actualizar el estado de la PQRS');
        setSuccessResponse(false);
      } finally {
        setLoadingTakePqrs(false);
      }
    },
    [takePqrs],
  );

  return [handleRespondPqrs, loadingTakePqrs, successResponse, error];
};
