import React, { useState } from 'react';

import Text from '../Text';
import styles from './styles.module.scss';
import { IDropMenuProps } from './types';

const DropMenu: React.FC<IDropMenuProps> = ({ items, isOpen, customClass }) => {
  const [hoverIndex, setHoverIndex] = useState<number | null>(null);

  return (
    <div
      className={`${styles.container} ${isOpen ? styles.open : ''} ${customClass}`}
    >
      {items.map((item, index) => (
        <div
          key={index}
          className={styles.menuItem}
          onClick={item.onClick}
          onMouseEnter={() => setHoverIndex(index)}
          onMouseLeave={() => setHoverIndex(null)}
        >
          {item.icon && (
            <div>
              {React.cloneElement(
                item.icon as React.ReactElement<{ color: string }>,
                {
                  color: hoverIndex === index ? 'primary' : 'secondary2',
                },
              )}
            </div>
          )}
          <Text text={item.label} textStyle={'B1-Regular'} />
        </div>
      ))}
    </div>
  );
};

export { DropMenu };
