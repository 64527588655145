import React, { useEffect, useState } from 'react';

import { Loading } from '../../ui/atoms/Loading';
import { SearchInput } from '../../ui/atoms/SearchInput';

import { ListEmpty } from '../../ui/molecules/ListEmpty';

import Table from '../../ui/organisms/Table';

import { useReports } from '../core/hooks/Visits.hook';
import styles from './styles.module.scss';

export const ReportVisitTable: React.FC = () => {
  const [reports] = useReports();
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredReports, setFilteredReports] = useState(reports || []);

  useEffect(() => {
    if (reports) {
      setFilteredReports(
        reports.filter((report) => {
          const visitorName = report.visitor_name?.toLowerCase() || '';
          const guardName = report.guard_name?.toLowerCase() || '';
          const destination = report.destination?.toLowerCase() || '';
          const visitorDocument = report.visitor_document?.toString() || '';
          const checkInDate = new Date(report.check_in).toLocaleString();

          return (
            visitorName.includes(searchTerm.toLowerCase()) ||
            guardName.includes(searchTerm.toLowerCase()) ||
            destination.includes(searchTerm.toLowerCase()) ||
            visitorDocument.includes(searchTerm) ||
            checkInDate.includes(searchTerm)
          );
        }),
      );
    }
  }, [searchTerm, reports]);

  const columns = [
    { title: 'Visitante', sortable: true, type: 'string' as const },
    { title: 'Destino', sortable: true, type: 'string' as const },
    { title: '# Documento', sortable: true, type: 'number' as const },
    { title: 'Fecha y hora entrada', sortable: true, type: 'date' as const },
    { title: 'Guarda', sortable: true, type: 'string' as const },
  ];

  const data =
    filteredReports?.map((report) => [
      { text: report.visitor_name },
      { text: report.destination },
      { text: report.visitor_document?.toString() || 'N/A' },
      { text: new Date(report.check_in).toLocaleString() },
      { text: report.guard_name },
    ]) || [];

  return (
    <div className={styles.main}>
      <div className={styles.searchInput}>
        <SearchInput
          placeholder='Buscar por visitante, guarda, fecha, apartamento o documento'
          value={searchTerm}
          onChangeText={setSearchTerm}
        />
      </div>
      <div className={styles.tableContainer}>
        {reports ? (
          filteredReports.length > 0 ? (
            <Table imageSrc='visit' columns={columns} data={data} />
          ) : (
            <ListEmpty width={150.6} height={164.3} />
          )
        ) : (
          <Loading size='small' backgroundColor={true} />
        )}
      </div>
    </div>
  );
};
